<template>
<div>

  <div class="container-fluid wrapper-medium content-form">
    <div class="row">
      <div class="col-12 col-md-6">
        <figure class="img-gesture-round mb-2 ml-auto mr-auto ml-md-0">
          <img :src="$randomGestureImage('phone', 2)" alt="">
        </figure>
        <h1 class="mb-3 anim-load1">
          Verify it's you
        </h1>
        <h4 class="mb-3 anim-load2">Please complete this step immediately</h4>
        <p class="anim-load3">An SMS message containing a verification code has been sent to your verification phone number</p>
      </div>
      <div class="col-12 col-md-6">
        <div class="card mt-3 mt-md-0">
          <div class="card-body">
              <ValidationObserver ref="form">
                <b-form @submit.prevent="onSubmit" class="mb-3">
                  <div class="form-group" ref="verificationCode">
                    <label for="verificationCode">Enter verification code</label>
                    <ValidationProvider v-slot="{classes, errors}" rules="required" name="Verification code">
                      <input autocomplete="one-time-code" ref="verificationCodeRef" v-model="form.verificationCode" type="number" placeholder="" name="verificationCode" class="form-control form-control-no-spinner" v-bind:class="classes">
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>

                  <b-alert variant="danger"
                    dismissible
                    :show="showDismissibleAlert"
                    @dismissed="showDismissibleAlert=false">
                    {{serverError}}
                  </b-alert>
                  <button type="submit" class="btn btn-warning btn-lg btn-block" >Verify and sign in</button>
                </b-form>
              </ValidationObserver> 
          </div>
        </div>
        <div class="text-center mt-3">
          <p class="">Didn't receive the code? <b-link class="btn-link" v-on:click="goBack()">Sign in again</b-link></p>
        </div>
      </div>
    </div>
  </div>

  <Footer />

</div>
</template>

<script>


export default {
  name: 'SigninVerification',
  data () {
    return {
      resourcesLoaded: 0,
      resourcesToLoad: 0,
      form: {
        verificationCode: ''
      },
      termsError: false,
      showDismissibleAlert: false,
      serverError: '',
      setupForm: null
    }
  },
  created() {
    this.$store.state.loadingProgress = false
    this.$store.state.appSection = 'brochure'
    localStorage.removeItem('jwt')
    if (this.$store.state.jwt === null || !this.$store.state.signInProcess) {
      this.$router.push('/');
    }
    this.$nextTick(() => {
      this.$refs.verificationCodeRef.focus();
    })
  },
  methods: {
    showError(txt) {
      this.serverError = txt
      this.showDismissibleAlert = true
    },

    onSubmit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            var el = this.$refs.form.refs[errors[0]["key"]].$el
            this.$scrollTo(el, 500, {offset: -100, cancelable: false})
          }, 100);
        } else {
          this.$store.state.loadingProgress = true
          this.$http({
            method: 'post', 
            url: this.$api.AuthTwoFactorLogin,
            params: {
              code: this.form.verificationCode
            },
            headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
          })
          .then((response) => {
            if (response.data.token !== undefined && response.data.token !== null) {
              var token = response.data.token
              this.$store.state.jwt = token
              this.$store.state.jwtDecoded = this.$decodeToken(token)
              localStorage.setItem('jwt', token)
              this.$store.state.signInProcess = false
              this.$router.push('/dashboard/')
              .catch(error => {
                console.info(error.message)
              })
            } else if (response.data.token === null && response.data.status === 1) {
              this.alertMsgShow('Too many unsuccessful login attempts. Please try again in 5 minutes.', false);
              this.$router.push('/sign-in/')
            } else {
              this.showError('Incorrect code. Please try again.')
            }
          })
          .catch((error) => {
            console.error('Error', error)
            this.showError('Incorrect code. Please try again.')
          })
          .finally(() => {
            this.$store.state.loadingProgress = false
          })
        }
      });
    },
  }
}
</script>
